import Index from "views/Index.js";
import Survey from "views/Survey.js";
import Login from "views/Login.js";
import Newsletter from "views/Newsletter.js";
import Users from "views/Users.js";
import Termin from "views/Termin.js";
import Reviews from "views/Reviews.js";
import UserProfile from "views/UserProfile";
import Support from "views/Support";
import Notification from "views/Notification";
import TerminSurvey from "views/TerminSurvey";
import RatingSurvay from "views/RatingSurvay";
import Coins from "views/Coins";
import UserReports from "views/UserReports";
import UsersWallet from "views/UsersWallet";
import InsertWallet from "views/InsertWallet";
import UpdateWallet from "views/UpdateWallet";
import BackendTest from "views/BackendTest";

const dashboardIcon = require("assets/img/icons/dashboard.png");
const usersIcon = require("assets/img/icons/users.png");
const actionIcon = require("assets/img/icons/survey.png");
const reviewsIcon = require("assets/img/icons/reviews.png");
const notificationIcon = require("assets/img/icons/notification.png");
const terminIcon = require("assets/img/icons/termin.png");
const supportIcon = require("assets/img/icons/support.png");
const newsletterIcon = require("assets/img/icons/newsletter.png");
const coinsIcon = require("assets/img/icons/coins.png");
const userReports = require("assets/img/icons/userReports.png");
const userReportsActive = require("assets/img/icons/userReportsActive.png");


const coinsIconActive = require("assets/img/icons/coinsActive.png");

const dashboardIconActive = require("assets/img/icons/dashboardActive.png");
const usersIconActive = require("assets/img/icons/usersActive.png");
const actionIconActive = require("assets/img/icons/surveyActive.png");
const reviewsIconActive = require("assets/img/icons/reviewsActive.png");
const notificationIconActive = require("assets/img/icons/notificationActive.png");
const terminIconActive = require("assets/img/icons/terminActive.png");
const supportIconActive = require("assets/img/icons/supportActive.png");
const newsletterIconActive = require("assets/img/icons/newsletterActive.png");

var routes = [
  {
    path: "/index",
    name: "Übersicht",
    icon: dashboardIcon,
    iconActive: dashboardIconActive,
    component: <Index />,
    layout: "/admin",
    display: "displayTrue"
  },
  {
    path: "/users",
    name: "Benutzer",
    icon: usersIcon,
    iconActive: usersIconActive,
    component: <Users />,
    layout: "/admin",
    display: "displayTrue"
  },
  {
    path: "/survey",
    name: "Umfrage",
    icon: actionIcon,
    iconActive: actionIconActive,
    layout: "/admin",
    display: "displayTrue",
      children: [
        {  
          path: "/survay/termin-survey",
          name: "Terminumfrage",
          component: <TerminSurvey />,
          layout: "/admin",
          display: "displayFalse",
         },
        {  
          path: "/survay/rating-survay",
          name: "Bewertungsumfrage",
          component: <RatingSurvay />,
          layout: "/admin",
          display: "displayFalse",
         },
      ]
  },
  {
    path: "/reviews",
    name: "Bewertungen",
    icon: reviewsIcon,
    iconActive: reviewsIconActive,
    component: <Reviews />,
    layout: "/admin",
    display: "displayTrue"
  },
  {
    path: "/newsletter",
    name: "Newsletter",
    icon: newsletterIcon,
    iconActive: newsletterIconActive,
    component: <Newsletter />,
    layout: "/admin",
    display: "displayTrue"
  },
  {
    path: "/termin",
    name: "Termin",
    icon: terminIcon,
    iconActive: terminIconActive,
    component: <Termin />,
    layout: "/admin",
    display: "displayTrue"
  },
  {
    path: "/coins",
    name: "Kryptowährungsliste",
    icon: coinsIcon,
    iconActive: coinsIconActive,
    component: <Coins />,
    layout: "/admin",
    display: "displayTrue"
  },
  {
    path: "/users-reports",
    name: "Benutzerberichte",
    icon: userReports,
    iconActive: userReportsActive,
    component: <UserReports />,
    layout: "/admin",
    display: "displayTrue"
  },
  {
    path: "/users/:id",
    name: "Profile",
    icon: newsletterIcon,
    component: <UserProfile />,
    layout: "/admin",
    display: "displayFalse"
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: <Login />,
    layout: "/auth",
    display: "displayFalse"
  },
  {
    path: "/support",
    name: "Unterstützung",
    icon: supportIcon,
    iconActive: supportIconActive,
    component: <Support />,
    layout: "/admin",
    display: "displayTrue topMg"
  },
  {
    path: "/notification",
    name: "Benachrichtigung",
    icon: notificationIcon,
    iconActive: notificationIconActive,
    component: <Notification />,
    layout: "/admin",
    display: "displayTrue"
  },
  {  
    path: "/survay/termin-survey",
    name: "Termin Survey",
    component: <TerminSurvey />,
    layout: "/admin",
    display: "displayFalse",
   },
  {  
    path: "/survay/rating-survay",
    name: "Rating Survey",
    component: <RatingSurvay />,
    layout: "/admin",
    display: "displayFalse",
   },
   {
    path: "/userswallet",
    name: "UsersWallet",
    icon: "ni ni-key-25 text-info",
    component: <UsersWallet />,
    layout: "/admin",
    display: "displayFalse"
  },
  {
    path: "/insertwallet",
    name: "InsertWallet",
    icon: "ni ni-key-25 text-info",
    component: <InsertWallet />,
    layout: "/admin",
    display: "displayFalse"
  },
  {
    path: "/updatewallet",
    name: "UpdateWallet",
    icon: "ni ni-key-25 text-info",
    component: <UpdateWallet />,
    layout: "/admin",
    display: "displayFalse"
  },
  {
    path: "/backend-test",
    name: "BackendTest",
    icon: "ni ni-key-25 text-info",
    component: <BackendTest />,
    layout: "/admin",
    display: "displayFalse"
  },
];
export default routes;
