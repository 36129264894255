import React, { useState, useEffect, useContext } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import { PropTypes } from "prop-types";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase-config";  // Firebase config
import {
  Navbar,
  NavbarBrand,
  Container,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  Media,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import { AuthContext } from "../../context/AuthContext";
import LogOutIcon from "../../assets/img/icons/shutdown.png";

const Sidebar = (props) => {
  const { dispatch } = useContext(AuthContext);
  const [collapseOpen, setCollapseOpen] = useState();
  const [unseenCount, setUnseenCount] = useState(0);

  // Firebase listener for unseen notifications
  useEffect(() => {
    const notificationsQuery = query(
      collection(db, "notifications"),
      where("seen", "==", false)
    );

    const unsubscribe = onSnapshot(notificationsQuery, (snapshot) => {
      setUnseenCount(snapshot.size); // Update unseen count
    });

    return () => unsubscribe(); // Clean up on unmount
  }, []);

  const handleLogout = () => dispatch({ type: "LOGOUT" });

  const toggleCollapse = () => setCollapseOpen(!collapseOpen);

  const closeCollapse = () => setCollapseOpen(false);

  const DropdownLink = ({ prop, closeCollapse }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(!dropdownOpen);

    return (
      <Dropdown className="displayTrue" nav inNavbar isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle nav caret>
          <img src={prop.icon} alt="" /> {prop.name}
        </DropdownToggle>
        <DropdownMenu>
          {prop.children.map((child, index) => (
            <DropdownItem key={index}>
              <NavLink
                to={child.layout + child.path}
                tag={NavLinkRRD}
                onClick={closeCollapse}
                className="nav-link"
              >
                <img src={child.icon} alt="" />
                <img src={child.iconActive} className="activeIcon" alt="" />
                {child.name}
              </NavLink>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    );
  };

  const createLinks = (routes, closeCollapse) =>
    routes.map((prop, key) => {
      if (prop.children) {
        return <DropdownLink key={key} prop={prop} closeCollapse={closeCollapse} />;
      }

      return (
        <NavItem key={key} className={prop.display}>
          <NavLink
            to={prop.layout + prop.path}
            tag={NavLinkRRD}
            onClick={closeCollapse}
          >
            <img src={prop.icon} alt="" />
            <img src={prop.iconActive} className="activeIcon" alt="" />
            {prop.name}
            {prop.name === "Benachrichtigung" && unseenCount > 0 && (
              <span className="notification-count">{unseenCount}</span>
            )}
          </NavLink>
        </NavItem>
      );
    });

  const { routes, logo } = props;

  let navbarBrandProps = {};
  if (logo) {
    navbarBrandProps = logo.innerLink
      ? { to: logo.innerLink, tag: Link }
      : { href: logo.outterLink, target: "_blank" };
  }

  return (
    <Navbar className="navbar-vertical fixed-left navbar-dark sidebar-bgColor" expand="md" id="sidenav-main">
      <Container fluid>
        {logo && (
          <NavbarBrand className="pt-0 text-left" {...navbarBrandProps}>
            <img alt={logo.imgAlt} className="navbar-brand-img" src={logo.imgSrc} />
          </NavbarBrand>
        )}

        <Collapse navbar isOpen={collapseOpen}>
          <Nav navbar>{createLinks(routes, closeCollapse)}</Nav>
          <hr className="my-3" />
          <Media className="align-items-center absolute bottom-0 profilUser">
            <span className="avatar avatar-sm rounded-circle logoutBtn" onClick={handleLogout}>
              <img src={LogOutIcon} alt="Log Out" />
            </span>
            <Media className="ml-2 d-none d-lg-block">
              <span className="mb-0 text-white text-sm font-weight-bold">Admin</span>
            </Media>
          </Media>
        </Collapse>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    innerLink: PropTypes.string,
    outterLink: PropTypes.string,
    imgSrc: PropTypes.string.isRequired,
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
