import { useState, useEffect } from "react";
import {
  Badge,
  Card,
  Col,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import Header from "components/Headers/Header.js";
import AddNewUser from '../components/Forms/AddNewUser';
import { Button, Modal } from 'react-bootstrap';
import AddNewUserBg from '../assets/img/theme/dashboardTopContentBg.png';
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { deleteUser as deleteAuthUser } from "firebase/auth";
import { db, auth } from "../firebase-config";
import { useNavigate, useHistory } from "react-router-dom";
import { format } from "date-fns";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import trashIcon from '../assets/img/icons/trash.png'; 
import alertIcon from '../assets/img/icons/alert.png';
import TradingFormModal from "../components/TradingForm/TradingFormModal";

// Initializing the users state at the top
const Users = () => {
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [userCount, setUserCount] = useState(0);
  const [show, setShow] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null); 
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  // Number formatter for CHF currency
  const chfFormatter = new Intl.NumberFormat('de-CH', {
    style: 'currency',
    currency: 'CHF',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  // Function to flatten user data
  function flattenUserData(user) {
    const { investmentDetails, dob, ...rest } = user;
    const investmentDate = investmentDetails ? investmentDetails.investmentDate : null;
    const startKapital = investmentDetails ? investmentDetails.startCapital : null;
    const currentNetworth = investmentDetails ? investmentDetails.goalCapital : null;
    const formattedInvestmentDate = investmentDate ? formatInvestmentDate(investmentDate) : null;
    const formattedDob = dob ? formatInvestmentDate(dob) : null;
    return { ...rest, investmentDate: formattedInvestmentDate, startKapital, currentNetworth, dob: formattedDob };
  }

  function formatInvestmentDate(timestamp) {
    const date = timestamp.toDate();
    return format(date, "dd.MM.yyyy"); // Adjust the format as needed
  }

  const fetchUsers = async () => {
    try {
      const userCollection = collection(db, "users");
      const userSnapshot = await getDocs(userCollection);
      const userList = userSnapshot.docs.map(doc => {
        const userData = { id: doc.id, ...doc.data() };
        return flattenUserData(userData);
      });
      setUsers(userList);
      setUserCount(userList.length); 
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);
  const filteredUsers = users.filter((user) => {
    return (
      (user.name?.toLowerCase() || "").includes(searchQuery.toLowerCase()) ||
      (user.dob?.toLowerCase() || "").includes(searchQuery.toLowerCase()) ||
      (user.investmentDate?.toLowerCase() || "").includes(searchQuery.toLowerCase()) ||
      chfFormatter.format(user.startKapital || 0).toLowerCase().includes(searchQuery.toLowerCase()) ||
      chfFormatter.format(user.currentNetworth || 0).toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const handleDeleteUser = async () => {
    if (selectedUser) {
      try {
        await deleteDoc(doc(db, "users", selectedUser.id));
        const currentUser = auth.currentUser;
        if (currentUser && currentUser.uid === selectedUser.uid) {
          await currentUser.delete(); 
        } else {
          await deleteAuthUser(selectedUser.uid);  
        }
  
        setUsers(users.filter(user => user.id !== selectedUser.id));
        setUserCount(prevCount => prevCount - 1);
        toast.success("Benutzer erfolgreich gelöscht.");
      } catch (error) {
        console.error("Error deleting user:", error);
        toast.error("Failed to delete user.");
      } finally {
        setConfirmDelete(false);
        setSelectedUser(null);
      }
    }
  };
  
  // Show delete confirmation
  const showDeleteConfirmation = (user) => {
    setSelectedUser(user);
    setConfirmDelete(true);
  };
  
  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <button onClick={() => navigateToProfile(rowData.id)}>
          Bearbeiten
        </button>
        <button onClick={() => showDeleteConfirmation(rowData)} style={{ marginLeft: '10px', color: 'red' }}>
        <img src={trashIcon} alt="Delete"/>
        </button>
      </>
    );
  }

  const navigateToProfile = (id) => {
    window.open(`/admin/users/${id}`, "_blank");
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormSubmit = async (message) => {
    toast.success(message, {});
    await fetchUsers();
    setTimeout(() => {
      setShow(false);
    }, 3000); 
  };

  const showSuccessToast = () => {
    toast.success('Trading history successfully added!', {
      autoClose: 5000,  // Optional, defaults to 5000 if not specified
    });
  };

  const divStyle = {
    backgroundImage: `url(${AddNewUserBg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
      <h3 className="text-white text-xl mb-4">Benutzer</h3>
      <Row className="gx-3">
        <Col className="mb-5 mb-xl-0" xl="3">
          <Row>
           <Col className="headerRows mb-3 mt-3" lg="12" xl="12">
            <div style={divStyle}>
            <span>Gesamtbenutzer</span>
            <span className="mainN">{userCount}</span>
            </div>
           </Col>
           <Col className="headerRows mb-3 mt-3" lg="6" xl="6">
           
           </Col>
           </Row>
        </Col>
        <Col className="mb-5 mb-xl-0 searchUserInp" xl="9">
          <Row className="mb-3">
          <Col className="mb-3" xl="6"></Col>
            <Col className="mb-3 addCustomerBtn" xl="6">
            <button onClick={openModal} className="multipleTradButton">+ Neue Daten hinzufügen</button>
              <a href="#" onClick={handleShow}>+ Kunden hinzufügen</a>
              <div className="p-inputgroup" style={{ marginBottom: '1rem' }}>
                <span className="p-inputgroup-addon">
                  <i className="pi pi-search"></i>
                </span>
                <InputText
                  placeholder="Suche..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </Col>
          </Row>            
        </Col>
      </Row>
      <Row className="gx-3 usersTablePg">
        <Col>
          <DataTable value={filteredUsers} className="usersTable align-items-center" paginator rows={25} tableStyle={{ minWidth: '100%' }}>
            <Column field="name" header="NAME" style={{ width: '12%' }}></Column>
            <Column field="dob" header="GEBURSDATUM" style={{ width: '12%' }}></Column>
            <Column field="investmentDate" header="DATUM DER INVESTITION" style={{ width: '12%' }}></Column>
            <Column field="startKapital"  header="STARTKAPITAL" style={{ width: '12%' }} body={(rowData) => chfFormatter.format(rowData.startKapital)} ></Column>
            <Column field="currentNetworth" header="ZIEL" style={{ width: '12%' }} body={(rowData) => chfFormatter.format(rowData.currentNetworth)} ></Column> 
            <Column header="" style={{ width: '12%' }} body={actionBodyTemplate}></Column>
          </DataTable>
        </Col>
      </Row>
      <Modal show={confirmDelete} className="deleteUserConfirmation" onHide={() => setConfirmDelete(false)}>
      <Modal.Header style={divStyle}>
          <Modal.Title>Löschen bestätigen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <br></br>
          <p className="alertIcon"><img src={alertIcon} alt="Delete"/></p><br></br>
          <p>Sind Sie sicher, dass Sie diesen Benutzer löschen möchten?</p> 
          <p>Diese Aktion kann nicht rückgängig gemacht werden.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setConfirmDelete(false)}>
            Abbrechen
          </Button>
          <Button variant="danger" onClick={handleDeleteUser}>
            Löschen
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal className="addNewUserModal" show={show} onHide={handleClose}>
        <Modal.Header style={divStyle}>
          <Modal.Title>Neuer Benutzer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddNewUser onSubmit={handleFormSubmit} />
          {successMessage && <p className="text-success mt-3">{successMessage}</p>}
        </Modal.Body>
      </Modal>
      <ToastContainer />

      <TradingFormModal show={isModalOpen} handleClose={closeModal}  onSuccess={showSuccessToast} />
      </Container>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />
    </>
  );
};

export default Users;
