import { useState, useEffect } from "react";
import starsImg from '../assets/img/icons/reviewsStar.png';
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  Badge,
  Card,
  Col,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase-config";
import Header from "components/Headers/Header.js";
import emailjs from "emailjs-com";

const Icons = () => {
  const [users, setUsers] = useState([]);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);
  const [selectedClients, setSelectedClients] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");

  // Fetch users from Firestore
  useEffect(() => {
    const fetchUsers = async () => {
      const usersCollection = collection(db, "users");
      const usersSnapshot = await getDocs(usersCollection);
      const usersList = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setUsers(usersList);
    };

    fetchUsers();
  }, []);

  // Convert file to base64
  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]); // Extract base64 string without metadata
      reader.onerror = error => reject(error);
    });
  };

  // Handle file input change
  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(await convertFileToBase64(selectedFile));
    }
  };

  // Handle checkbox change for selecting users
  const handleClientSelection = (e) => {
    const { checked, value } = e.target;
    setSelectedClients(prevState =>
      checked ? [...prevState, value] : prevState.filter(client => client !== value)
    );
  };

  const sendEmail = (e) => {
    e.preventDefault();

    // Extract selected client emails
    const selectedClientEmails = selectedClients.map(id => {
      const user = users.find(user => user.id === id);
      return user.email;
    });

    // Join emails into a single string, separated by commas
    const recipientEmails = selectedClientEmails.join(", ");

    // Prepare the email data object with required fields
    const emailData = {
      to_email: recipientEmails,
      from_name: "NETWRK",
      reply_to: "lb.liridonbajrami@gmail.com",
      subject: subject,
      message: message,
      attachment: file ? [{
        content: file,
        filename: 'attachment.pdf', // Adjust filename and extension as needed
        type: 'application/pdf', // Adjust MIME type as needed
        disposition: 'attachment'
      }] : []
    };

    // Send the email using EmailJS
    emailjs.send(
      "service_safy3ow",
      "template_tjjk3uk",
      emailData,
      "rh_MIDgd2GRZQHuwU"
    )
    .then((response) => {
      console.log("Email sent successfully!", response.status, response.text);
      setSuccessMessage("Newsletter erfolgreich verschickt!");
    })
    .catch((error) => {
      console.error("Failed to send email.", error);
    });
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <h3 className="text-white text-xl mb-4">Newsletter</h3>
        <div className="card-bgColor portfolioUserWallet newsletterPage">
          <Row className="gx-3">
            <Col className="mb-5 mb-xl-0" xl="4">
              <ul className="newsletterUsersAndEmails">
                {users.map((user) => (
                  <li key={user.id}>
                    <span className="nwslName">{user.name} </span><br/>
                    <span className="nwslEmail">{user.email}</span>
                    <input
                      type="checkbox"
                      value={user.id}
                      onChange={handleClientSelection}
                    />
                  </li>
                ))}
              </ul>
            </Col>
            <Col className="mb-5 mb-xl-0" xl="8">
              <form onSubmit={sendEmail}>
                <label>BETREFF TITEL</label>
                <input
                  type="text"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  required
                />
                <label>File:</label>
                <input
                  type="file"
                  name="file"
                  onChange={handleFileChange}
                />
                <label>NACHRICHT</label>
                <textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                />
                <button type="submit">Send</button>
              </form>
              {successMessage && <p className="text-success mt-3">{successMessage}</p>}
            </Col>
            
          </Row>
          
        </div>
      </Container>
    </>
  );
};

export default Icons;
