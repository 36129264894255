import { useState, useEffect } from "react";
import { collection, getDocs, query, where, doc, updateDoc, limit, startAfter } from "firebase/firestore";
import { db } from "../firebase-config";
import Header from "components/Headers/Header.js";
import { Col, Container, Row } from "reactstrap";

const Reviews = () => {
  const [reviews, setReviews] = useState([]);
  const [filter, setFilter] = useState("all");
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const pageSize = 10; // Number of reviews per page

  // Fetch reviews with pagination
  const fetchReviews = async (next = false) => {
    if (loading) return;

    setLoading(true);
    try {
      let q = query(
        collection(db, "survey"),
        where("surveyType", "==", "RATING"),
        limit(pageSize)
      );

      if (next && lastVisible) {
        q = query(
          collection(db, "survey"),
          where("surveyType", "==", "RATING"),
          startAfter(lastVisible),
          limit(pageSize)
        );
      }

      const surveySnapshot = await getDocs(q);
      const lastDoc = surveySnapshot.docs[surveySnapshot.docs.length - 1];
      const results = [];

      for (const surveyDoc of surveySnapshot.docs) {
        const resultsCollection = collection(surveyDoc.ref, "result");
        const resultsSnapshot = await getDocs(resultsCollection);

        if (!resultsSnapshot.empty) {
          resultsSnapshot.forEach((resultDoc) => {
            const data = resultDoc.data();
            results.push({
              id: resultDoc.id,
              surveyId: surveyDoc.id,
              name: data.name || "N/A",
              ratings: data.ratings || 0,
              review: data.review || "No review provided",
              ratingStatus: data.ratingStatus || "Pending",
              ratingDate: data.createdAt
                ? data.createdAt.toDate().toLocaleString()
                : "",
            });
          });
        }
      }

      setHasMore(surveySnapshot.docs.length === pageSize);
      setLastVisible(lastDoc);
      setReviews((prev) => (next ? [...prev, ...results] : results));
    } catch (error) {
      console.error("Error fetching reviews: ", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchReviews();
  }, []);

  const handleStatusChange = async (surveyId, resultId, status) => {
    try {
      const resultDocRef = doc(db, "survey", surveyId, "result", resultId);
      await updateDoc(resultDocRef, { ratingStatus: status });
      setReviews((prevReviews) =>
        prevReviews.map((review) =>
          review.id === resultId ? { ...review, ratingStatus: status } : review
        )
      );
    } catch (error) {
      console.error("Error updating status: ", error);
    }
  };

  const filteredReviews = () => {
    return reviews
      .filter((review) => review.ratings && review.review) // Exclude invalid reviews
      .filter((review) => {
        if (filter === "approved") {
          return review.ratingStatus === "Approved";
        } else if (filter === "declined") {
          return review.ratingStatus === "Declined";
        }
        return true; // Return all if no filter
      });
  };
  

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row className="gx-3">
          <Col className="mb-5 mb-xl-0" xl="9">
            <h3 className="text-white text-xl mb-4">Bewertungen</h3>
          </Col>
        </Row>

        <Row className="revCButtons mb-4">
          <Col xl="12">
            <a
              href="#"
              className={filter === "all" ? "active" : ""}
              onClick={() => setFilter("all")}
            >
              Alle Bewertungen
            </a>
            <a
              href="#"
              className={filter === "approved" ? "active" : ""}
              onClick={() => setFilter("approved")}
            >
              Veröffentlicht
            </a>
            <a
              href="#"
              className={filter === "declined" ? "active" : ""}
              onClick={() => setFilter("declined")}
            >
              Abgelehnt
            </a>
          </Col>
        </Row>
        <Row className="gx-3 reviewsContent mb-3">
  {filteredReviews().length > 0 ? (
    filteredReviews().map((review) => (
      <Col className="mb-3" xl="3" key={review.id}>
        <div>
          <p className="rUsrName">{review.name}</p>
          <p className="rUsrDate">
            {review.ratingDate || ""}
          </p>
          <p className="ratingNumber">
            {Array.from({ length: review.ratings }, (_, index) => (
              <span key={index}>&#9733;</span> // Star symbol
            ))}
          </p>
          <p className="rComment">{review.review}</p>
          <ul className="reviewsBtn">
                                  {review.ratingStatus === "Approved" ? (
    <li>
        <button
            onClick={() => handleStatusChange(review.surveyId, review.id, "Approved")}
            className="approveBtn"
            style={{ opacity: 0.5, pointerEvents: "none" }} // Apply styles for disabled button
        >
            Approve
        </button>
    </li>
) : (
    <li>
        <button
            onClick={() => handleStatusChange(review.surveyId, review.id, "Approved")}
            className="approveBtn"
        >
            Approve
        </button>
    </li>
)}

{review.ratingStatus === "Declined" ? (
    <li>
        <button
            onClick={() => handleStatusChange(review.surveyId, review.id, "Declined")}
            className="declineBtn"
            style={{ opacity: 0.5, pointerEvents: "none" }} // Apply styles for disabled button
        >
            Decline
        </button>
    </li>
) : (
    <li>
        <button
            onClick={() => handleStatusChange(review.surveyId, review.id, "Declined")}
            className="declineBtn"
        >
            Decline
        </button>
    </li>
)}
                                  </ul>
        </div>
      </Col>
    ))
  ) : (
    <p>No reviews available.</p>
  )}
</Row>


        {hasMore && (
          <Row>
            <Col className="text-center">
              <button onClick={() => fetchReviews(true)} disabled={loading}>
                {loading ? "Loading..." : "Load More"}
              </button>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};

export default Reviews;
