// Notification.js
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../firebase-config"; // Adjust import path
import { collection, getDocs, doc, updateDoc } from "firebase/firestore";

const Notification = () => {
  const [notifications, setNotifications] = useState([]);

  // Fetch notifications
  const fetchNotifications = async () => {
    try {
      const notificationCollection = collection(db, "notifications");
      const notificationSnapshot = await getDocs(notificationCollection);

      const notificationList = notificationSnapshot.docs.map((doc) => {
        const notificationData = { id: doc.id, ...doc.data() };
        return formatNotification(notificationData);
      });

      setNotifications(notificationList);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  // Format notification links
  const formatNotification = (notification) => {
    let link = "#";
    if (notification.type === "RATING") link = "/admin/reviews";
    if (notification.type === "APPOINTMENT") link = "/admin/termin";

    return {
      ...notification,
      link,
    };
  };

  // Update notification seen status
  const markAsSeen = async (id) => {
    try {
      const notificationDoc = doc(db, "notifications", id);
      await updateDoc(notificationDoc, { seen: true });
      // Refresh notifications after update
      fetchNotifications();
    } catch (error) {
      console.error("Error updating notification:", error);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <div>
      {notifications.length === 0 ? (
        <p>No new notifications</p>
      ) : (
        <ul className="notification-list">
          {notifications.map((notification) => (
            <li
              key={notification.id}
              className={`notification-item ${
                !notification.seen ? "unseen" : ""
              }`}
              onClick={() => markAsSeen(notification.id)}
            >
              <Link to={notification.link} className="notification-link">
                <div className="notification-content">
                  <p className="notification-comment">
                    {notification.comment}
                  </p>
                  <p className="notification-date">
                    {new Date(
                      notification.createdAt.seconds * 1000
                    ).toLocaleString()}
                  </p>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Notification;
